<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini_variant"
      :expand-on-hover="mini_variant"
      :permanent="$vuetify.breakpoint.smAndUp"
      app dark
    >
      <v-list dark class="left-nav">
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{userProfile.fullname}}
            </v-list-item-title>
            <v-list-item-subtitle>{{userProfile.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense dark>

        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-group v-for="(parent, pidx) in menuParents" :key="pidx" :prepend-icon="parentIcons[pidx]" no-action v-show="menuExist(parent)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="parent"></v-list-item-title>
            </v-list-item-content>            
          </template>
          <v-list-item v-for="(menu, idx) in getMenus(parent)" :key="idx" link :to="menu.menu_url" v-show="userPermissions.includes(menu.id)">
            <v-list-item-title>{{menu.menu_name}}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>MamaRoz Recipe System</v-toolbar-title>
    </v-app-bar>

    <v-main style="background-color:#EBEBEB">
      <!-- <router-view :key="$route.fullPath" style="margin-bottom:100px" /> -->
      <slot style="margin-bottom:100px"></slot>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    drawer: true,
    menus: null,
    masterMenu: null,
    trxMenu: null,
    reportMenu: null,
    mgtMenu: null,
    parentIcons: [
        'mdi-database-settings',
        'mdi-warehouse',
        'mdi-calendar-arrow-right',
        'mdi-coffee-maker',
        'mdi-truck-cargo-container',
        'mdi-chart-areaspline',
        'mdi-cog-outline'
    ]
  }),

  async created() {
    console.log(Object.keys(this.$store.state.profile).length)
    if (Object.keys(this.$store.state.profile).length===0) {
      this.$store.dispatch('getProfile').then(response => {
        console.log(response)
      })
    }
    this.$store.dispatch('misccode/getObjs')
    
    this.menus = (await this.$store.dispatch('menu/getObjs')).data.data
  },

  methods: {
      logout() {
          this.$store.commit('removeToken')
          this.$router.push('/login')
      },
      getMenus(parent) {
        if (this.menus) return this.menus.filter(element => {return element.parent_id==parent});
      },
      menuExist(parent) {
        let isExist = false
        let menus = this.getMenus(parent)
        if (!menus) return false
        menus.forEach(element => {
          if (this.userPermissions.includes(element.id)) {
            isExist = true
            return isExist
          }
        });
        return isExist
      }
  },

  computed: {
    mini_variant() {
      if (this.$vuetify.breakpoint.smAndUp){
        return this.drawer
      }
      else {
        return false
      }
    },
    menuParents() {
        return this.$store.state.menu.menuparents
    },
    userProfile() {
        return this.$store.state.profile
    },
    userPermissions() {
      return JSON.parse(this.userProfile.role.menu_id)
    }

  }
};
</script>

<style>
.theme--dark.v-navigation-drawer {
    background-color: #513C2E!important;
}

.v-list-group__header .v-list-item .v-list-item--active .v-list-item--link .theme--dark {
    color: #ffffff!important;
}

.v-application .primary--text {
  color: #ffffff!important;
}

::v-deep .left-nav .v-list-item-group .v-list-item--active {
  color: #ffffff!important;
}
</style>