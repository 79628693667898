<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view :key="$route.fullPath"></router-view>
    </component>
    <!-- <confirm ref="confirm" style="z-index: 99999;"></confirm> -->
    <!-- <v-snackbar v-model="snackbar_status" :color="snackbar_color" :timeout="6000" :top="snackbar_top" :left="snackbar_left" :right="snackbar_right" style="z-index: 999999;">
        {{ snackbar_msg }}
        <v-btn flat @click="snackbar_status = false">
            Close
        </v-btn>
    </v-snackbar>         -->
    <v-snackbar top v-model="snackbar.saveError" color="red accent-2">{{snackbar.errorMessage}}</v-snackbar>
    <v-snackbar top v-model="snackbar.saveSuccess" color="green">{{snackbar.successMessage}}</v-snackbar>

  </v-app>
</template>
<script>
const default_layout = "layout-default"

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout)
    },
    snackbar() {
      return this.$store.state.snackbar
    }
  },
  created() {

  }
}
</script>

<style>
.compact-form {
    transform: scale(0.875);
    transform-origin: left;
}

</style>
