// import axios from 'axios'
export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
        setObj(state, pload) {
            state.obj = pload
        },
    },
    actions: {
        postObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "orders"
                this._vm.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

        putObj({rootState}, {apidomain, payload}){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + apidomain
                this._vm.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        
        deleteObj({rootState}, order_id){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "orders?fields[order_id]=" + order_id
                console.log(url)
                this._vm.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    // commit('setObjs', response.data.data)
                    console.log(response.data.data)
                    resolve(response.data.data[0])
                }, error => {
                    reject(error)
                })
            })
        },

    },
}