import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routeList = [
  {path: '/', name: 'Home', requiresAuth: true, comp: Home},
  {path: '/orders', name: 'Orders', requiresAuth: true, comp: () => import('../views/Order.vue')},
  {path: '/orders/:batch/:action', name: 'Edit Order', requiresAuth: true, comp: () => import('../views/Order.vue')},
  {path: '/requestprod', name: 'Request Produksi 2', requiresAuth: true, comp: () => import('../views/RequestProd.vue')},
  {path: '/penerimaanrequestprod', name: 'List Produksi', requiresAuth: true, comp: () => import('../views/PenerimaanRequestProd.vue')},
  {path: '/penerimaanrequeststock', name: 'Penerimaan Request Stock', requiresAuth: true, comp: () => import('../views/PenerimaanRequestStock.vue')},
  {path: '/inputhasilproduksi/:prod_no', name: 'Input Hasil Produksi', requiresAuth: true, comp: () => import('../views/InputHasilProduksi.vue')},
  {path: '/viewhasilproduksi/:prod_no', name: 'View Hasil Produksi', requiresAuth: true, comp: () => import('../views/ViewHasilProduksi.vue')},
  {path: '/users', name: 'Users', requiresAuth: true, comp: () => import('../views/User.vue')},
  {path: '/roles', name: 'Roles', requiresAuth: true, comp: () => import('../views/Role.vue')},
  {path: '/suppliers', name: 'Suppliers', requiresAuth: true, comp: () => import('../views/Supplier.vue')},
  {path: '/customers', name: 'Customers', requiresAuth: true, comp: () => import('../views/Customer.vue')},
  {path: '/categories', name: 'Categories', requiresAuth: true, comp: () => import('../views/Category.vue')},
  {path: '/rawmaterials', name: 'Raw Materials', requiresAuth: true, comp: () => import('../views/RawMaterial.vue')},
  {path: '/variants', name: 'Variants', requiresAuth: true, comp: () => import('../views/Variant.vue')},
  {path: '/variantjuice', name: 'Variant Juice', requiresAuth: true, comp: () => import('../views/VariantJuice.vue')},
  {path: '/menus', name: 'Menus', requiresAuth: true, comp: () => import('../views/Menu.vue')},
  {path: '/instock', name: 'InStock', requiresAuth: true, comp: () => import('../views/InStock.vue')},
  {path: '/defectstock', name: 'DefectStock', requiresAuth: true, comp: () => import('../views/DefectStock.vue')},
  {path: '/permissions', name: 'Permissions', requiresAuth: true, comp: () => import('../views/Permission.vue')},
  {path: '/misccodes', name: 'Misc Codes', requiresAuth: true, comp: () => import('../views/MiscCode.vue')},
  {path: '/ordercategories', name: 'Order Categories', requiresAuth: true, comp: () => import('../views/OrderCategory.vue')},
  {path: '/planningprod', name: 'Planning Produksi', requiresAuth: true, comp: () => import('../views/PlanningProd.vue')},
  {path: '/orderplanningprod', name: 'Create Order', requiresAuth: true, comp: () => import('../views/OrderPlanningProd.vue')},
  {path: '/uploadorderplan/:batch/:order_id', name: 'Create Order Planning Produksi', requiresAuth: true, comp: () => import('../views/UploadOrderPlan.vue')},
  {path: '/requestplanningprod/:batch', name: 'Request Produksi', requiresAuth: true, comp: () => import('../views/RequestPlanningProd.vue')},
  {path: '/listhasilproduksi', name: 'List Hasil Produksi', requiresAuth: true, comp: () => import('../views/ListHasilProduksi.vue')},
  {path: '/deliveryorder', name: 'Delivery Order', requiresAuth: true, comp: () => import('../views/DeliveryOrder.vue')},
  {path: '/machine', name: 'Machine', requiresAuth: true, comp: () => import('../views/Machine.vue')},
  {path: '/packagingcost', name: 'Packaging Cost', requiresAuth: true, comp: () => import('../views/PackagingCost.vue')},
  {path: '/stockopname/:type', name: 'Stock Opname', requiresAuth: true, comp: () => import('../views/StockOpname.vue')},
  {path: '/stockopnamefg/:type', name: 'Stock Opname', requiresAuth: true, comp: () => import('../views/StockOpnameFG.vue')},
  {path: '/reports/frm003', name: 'Frm 003', requiresAuth: true, comp: () => import('../views/RptFrm003.vue')},
  {path: '/reports/frm005', name: 'Frm 005', requiresAuth: true, comp: () => import('../views/RptFrm005.vue')},
  {path: '/reports/production/:type', name: 'Report Production', requiresAuth: true, comp: () => import('../views/RptProduction.vue')},
  {path: '/reports/cost', name: 'Report Hitungan Cost', requiresAuth: true, comp: () => import('../views/RptCost.vue')},
  {path: '/reports/frmlogistik', name: 'Form Logistik', requiresAuth: true, comp: () => import('../views/RptFrmLogistik.vue')},
]

let routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresVisitor: true,
      layout: 'layout-login'
    },
    component: () => import('../views/Login.vue')
  }  
]
routeList.forEach(el => {
  routes.push(
    {
      path: el.path,
      name: el.name,
      meta: {
        requiresAuth: el.requiresAuth
      },
      component: el.comp
    }  
  )
})

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log(to, from)
  if (to.matched.some(record => record.meta.requiresAuth)) {

    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      console.log(to)
      if (to.path == '/') {
        next()
        return
      }
      // Check if the user has access to the menu
      if (store.state.menu.objs.length==0) {
        await store.dispatch('menu/getObjs')
      }
      if (store.state.profile.role == undefined) {
        await store.dispatch('getProfile')
      }
      const privMenu = JSON.parse(store.state.profile.role.menu_id)
      const currentMenu = store.state.menu.objs.find(el => el.menu_url==to.path)
      console.log(currentMenu)


      if (currentMenu==undefined) {
        next()
        return
      }

      if(privMenu.includes(currentMenu.id)==false){
        return
      }
      // end
      const createMenu = JSON.parse(store.state.profile.role.create_priv)
      const updateMenu = JSON.parse(store.state.profile.role.update_priv)
      const deleteMenu = JSON.parse(store.state.profile.role.delete_priv)
      store.commit('setCurrentPagePriv', {
        create: createMenu.includes(currentMenu.id),
        update: updateMenu.includes(currentMenu.id),
        delete: deleteMenu.includes(currentMenu.id),
      })
  
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
