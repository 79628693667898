// import axios from 'axios'
export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
        setObj(state, pload) {
            state.obj = pload
        },
    },
    actions: {
        getObjs({rootState, commit}){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "categories"
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObjs', response.data.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

    },
}