// import axios from 'axios'
export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
        setObj(state, pload) {
            state.obj = pload
        },
    },
    actions: {
        getObj({rootState}, do_no){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "deliveryOrders/" + do_no
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    // commit('setObjs', response.data.data)
                    resolve(response.data.data)
                }, error => {
                    reject(error)
                })
            })
        },
        getObjs({rootState, commit}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + `deliveryOrders?fields[category]=${payload.category}&fields[prod_no]=${payload.prod_no}`
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObjs', response.data.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        getOrderedVariants({rootState, commit}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "others/orderedvariants/" + payload.order_category + "/" + payload.prod_no
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObjs', response.data.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        getDeliveredVariants({rootState, commit}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "others/deliveredvariants/" + payload.order_category + "/" + payload.prod_no
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObjs', response.data.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        postObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "deliveryOrders"
                this._vm.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        putObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "deliveryOrders/" + payload.do_no
                this._vm.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

    },
}