// import axios from 'axios'
export default {
    namespaced: true,    
    state: {
        obj: {},
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
        setObj(state, pload) {
            state.obj = pload
        },
    },
    actions: {
        getObj({rootState, commit}, prod_no){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productions/" + prod_no
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObj', response.data.data)
                    resolve(response.data.data)
                }, error => {
                    reject(error)
                })
            })
        },
        getProductionsByStatus({rootState, commit}, status){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productions?fields[status]=" + status
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObjs', response.data.data)
                    resolve(response.data.data)
                }, error => {
                    reject(error)
                })
            })
        },
        getProductionVariants({rootState}, prod_no){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productionVariants?fields[prod_no]=" + prod_no
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        getProductionDefects({rootState}, prod_no){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productionDefects?fields[prod_no]=" + prod_no
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        getProductionsFinished({rootState, commit}){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "others/orderproductionfinished"
                this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    commit('setObjs', response.data.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        postObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productions"
                this._vm.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        putObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productions/" + payload.prod_no
                this._vm.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        postProductionVariants({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productionVariants"
                this._vm.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

        postProductionDefects({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "productionDefects"
                this._vm.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

    },
}