export default {
    namespaced: true,    
    state: {
        obj: [],
        objs: [],
    },
    mutations: {
        setObjs(state, pload) {
            state.objs = pload
        },
    },
    actions: {
        getObjs({rootState}, purl){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + purl
                console.log(url)
                fetch (url, {method: 'get', headers: rootState.apiheaders})
                .then (response => response.json())
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

    },
}