import Vue from 'vue';
    
Vue.mixin({
  methods: {
    cleanObj(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
            }
        }
        return obj
    },

    round2dec(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100
    }
  }
});
