import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLazyload from 'vue-lazyload'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import "vue-lazy-youtube-video/dist/style.simplified.css";
import LayoutLogin from '@/components/layouts/login' 
import LayoutDefault from '@/components/layouts/default' 
import VueCurrencyFilter from 'vue-currency-filter'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import VueHtmlToPaper from 'vue-html-to-paper';
import mixin from './plugins/mixin';

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.use(VueLazyload)
Vue.component('layout-login', LayoutLogin)
Vue.component('layout-default', LayoutDefault)
Vue.use(VueCurrencyFilter,
  {
    symbol : 'Rp',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  })
  
Vue.config.productionTip = false
Vue.prototype.$axios = axios;

Vue.filter('rounded', function (number) {
  return Math.round(Number(number))
})

Vue.filter('rounded2', function (number) {
  return Math.round((Number(number) + Number.EPSILON) * 100) / 100
})

Vue.filter('rounded20', function (number) {
  const pret = Math.round((Number(number) + Number.EPSILON) * 100) / 100
  if (pret==0) return ''
  else return pret
})

Vue.filter('numbers', function (number) {
  return Number(number).toLocaleString()
})

new Vue({
  router,
  store,
  vuetify,
  VueUploadMultipleImage,
  mixin,
  render: h => h(App)
}).$mount('#app')

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);