<template>
  <v-container class="home">
    <v-row>
      <v-col cols="12" class="text-center text-h4 pt-10">
        <img src="../assets/mamaroz-logo.png" height="200">
      </v-col>
    </v-row>
    
  </v-container>
</template>
<script>

export default {
  name: "Dashboard",
  components: {
  },
  data() {
    return {

    };
  },
  async mounted () {
 
  },  
  methods: {

  }
};
</script>


<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>