import Vue from 'vue'
import Vuex from 'vuex'
import role from './modules/role'
import category from './modules/category'
import supplier from './modules/supplier'
import customer from './modules/customer'
import rawmaterial from './modules/rawmaterial'
import variant from './modules/variant'
import reports from './modules/reports'
import menu from './modules/menu'
import misccode from './modules/misccode'
import order from './modules/order'
import production from './modules/production'
import ordercategory from './modules/ordercategory'
import deliveryorder from './modules/deliveryorder'
import stockopname from './modules/stockopname'


Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,  
  modules: {
    role,
    reports,
    category,
    supplier,
    customer,
    rawmaterial,
    variant,
    menu,
    misccode,
    order,
    production,
    ordercategory,
    deliveryorder,
    stockopname
  },
  state: {
    config: {
      backend: process.env.VUE_APP_ROOT_API + '/',
      apiHost: process.env.VUE_APP_ROOT_API + '/api/v1/',
      mediaHost: process.env.VUE_APP_ROOT_API + '/gallery/',
    },
    token: localStorage.getItem('adm_access_token') || null,
    profile: {},
    snackbar: {
      saveError: false,
      errorMessage: null,
      saveSuccess: false,
      successMessage: null
    },
    currentPagePriv: {}
  },
  getters: {
    loggedIn(state) {
      if (state.token === null || state.token === "undefined") return false
      else return true
    },
  },  
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('adm_access_token', token)
    },
    setProfile(state, payload) {
      state.profile = payload
    },
    removeToken(state) {
      state.token = null
      localStorage.removeItem('adm_access_token')
    },
    setSnackbar(state, payload) {
      state.snackbar.saveError = payload.saveError
      state.snackbar.errorMessage = payload.errorMessage
      state.snackbar.saveSuccess = payload.saveSuccess
      state.snackbar.successMessage = payload.successMessage
    },
    setCurrentPagePriv(state, payload) {
      state.currentPagePriv = payload
    }
  },
  actions: {
    login({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        const url = state.config.apiHost + 'login'

        this._vm.$axios.post(url, JSON.stringify(payload), {headers: {'Content-Type': 'application/json'}})
        .then(response => {
          console.log(response)
            commit('setToken', response.data.data.token)
            commit('setProfile', response.data.data.user)
            resolve(response)
        }, error => {
            reject(error)
        });
      })
    },

    getObjs({rootState}, {apidomain, parameter}){
      return new Promise((resolve, reject) => {
          let url = rootState.config.apiHost + apidomain + parameter
          this._vm.$axios.get(url, {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
          .then (response => {
              // commit('setObjs', response.data.data)
              resolve(response)
          }, error => {
              reject(error)
          })
      })
    },
    
    getProfile({state, commit}) {
      return new Promise((resolve, reject) => {
        const url = state.config.apiHost + 'profile'
        this._vm.$axios.get(url, {headers: {'Authorization': 'Bearer ' + state.token}})
        .then(response => {
            commit('setProfile', response.data.data)
            resolve(response)
        }, error => {
            reject(error)
        });
      })
    },

  },
})
