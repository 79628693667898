// import axios from 'axios'
export default {
    namespaced: true,    
    state: {
    },
    mutations: {
    },
    actions: {
        postObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "stockOpnames"
                this._vm.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        putObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "stockOpnames"
                this._vm.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
        delObj({rootState}, payload){
            return new Promise((resolve, reject) => {
                let url = rootState.config.apiHost + "stockOpnames"
                this._vm.$axios.delete(url, {data:JSON.stringify(payload), headers:{'Authorization': 'Bearer '+ rootState.token, 'Content-Type': 'application/json'}})
                .then (response => {
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },
    },
}